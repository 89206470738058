import { Typography, theme } from '@robinpowered/ui-kit';
import styled from '@emotion/styled';
import { useServiceDetailsSidebarContext } from '../contexts/ServiceDetailsSidebarContext';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from 'lib/utility';

export const Footer = () => {
  const { useToken } = theme;
  const { token } = useToken();
  const { serviceDetails } = useServiceDetailsSidebarContext();
  const { t } = useTranslation('ServiceDetailsSidebar');
  const formattedTotalPrice = useMemo(() => {
    return serviceDetails && serviceDetails.totalPrice
      ? formatCurrency(serviceDetails.totalPrice)
      : null;
  }, [serviceDetails]);

  if (!serviceDetails || !serviceDetails.totalPrice) {
    return null;
  }

  return (
    <Wrapper style={{ borderTop: `1px solid ${token.colorBorder}` }}>
      <Price>
        <Typography.Text>{t('footer.total')}</Typography.Text>
        <Typography.Text>{formattedTotalPrice}</Typography.Text>
      </Price>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  padding: 1rem;
  align-items: center;
`;

const Price = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;
