import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Select } from 'components/ServiceForm/components/Select/Select';
import { useServiceRequestContext } from 'components/ServiceRequest/contexts/ServiceRequestContext';
import { useEffect } from 'react';

export const SelectService = () => {
  const { t } = useTranslation('MeetingServiceRequest');
  const {
    selectService,
    selectedServiceWithoutQuestions,
    services,
    selectedCategory,
  } = useServiceRequestContext();

  const options = services.map((service) => ({
    value: service.name,
    label: service.name,
    id: service.id,
  }));

  useEffect(() => {
    if (
      selectedCategory.id &&
      services.length === 1 &&
      services[0].id !== selectedServiceWithoutQuestions?.id
    ) {
      selectService(services[0].id);
    }
  }, [
    selectService,
    selectedCategory,
    services,
    selectedServiceWithoutQuestions?.id,
  ]);

  if (!services.length) {
    return null;
  }

  return (
    <StyledAddWrapper>
      <Select
        disabled={services.length === 1}
        name={t(`select_service.label`)}
        label={t(`select_service.label`)}
        value={{
          value: selectedServiceWithoutQuestions?.name || '',
          label: selectedServiceWithoutQuestions?.name || '',
          id: selectedServiceWithoutQuestions?.id || '',
        }}
        placeholder={t(`select_service.label`)}
        options={options}
        onSelect={(value) => {
          selectService(value.id);
        }}
      />
    </StyledAddWrapper>
  );
};

const StyledAddWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
