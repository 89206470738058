import styled from '@emotion/styled';
import { Colors, Flex, SpinnerLoader } from '@robinpowered/design-system';
import {
  ServiceRequestContextProvider,
  useServiceRequestContext,
} from './contexts/ServiceRequestContext';
import { RobinLoader } from 'components/RobinLoader';
import { SelectCategory } from './components/SelectCategory';
import { SelectService } from './components/SelectService';
import { ServiceQuestions } from './components/ServiceQuestions';
import { MarkdownRenderer } from '../common/MarkdownRenderer/MarkdownRenderer';
import { Alert } from '@robinpowered/ui-kit';
import { useTranslation } from 'react-i18next';
import { ServiceDetailCard } from 'components/common/ServiceDetailCard/ServiceDetailCard';

const ServiceRequest = () => {
  const {
    loading,
    selectedServiceWithQuestions,
    isRecurring,
    gettingServiceWithQuestions,
    requestedBy,
    dueDate,
    locationName,
    numOfAttendees,
    loadingLocation,
    selectedServiceWithoutQuestions,
  } = useServiceRequestContext();
  const { t } = useTranslation('MeetingServiceRequest');

  if (loading) {
    return <RobinLoader />;
  }

  return (
    <Wrapper>
      {isRecurring && (
        <Alert
          message={t(`recurrence_alert.add_service`)}
          type="info"
          showIcon
        />
      )}
      <ServiceDetailCard
        dueDate={dueDate}
        loading={loading || loadingLocation}
        requestedBy={requestedBy}
        hideEventTitle
        locationName={locationName}
        numOfAttendees={numOfAttendees}
        selectedServiceWithoutQuestions={selectedServiceWithoutQuestions}
        assignees={selectedServiceWithQuestions?.assignees}
      />

      <SelectCategory />
      <SelectService />
      {gettingServiceWithQuestions ? (
        <Flex
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            marginTop: '16px',
          }}
        >
          <SpinnerLoader />
        </Flex>
      ) : (
        <>
          {selectedServiceWithQuestions &&
            selectedServiceWithQuestions.description && (
              <MarkdownRenderer
                markdownString={selectedServiceWithQuestions.description}
              />
            )}
          <ServiceQuestions />
        </>
      )}
    </Wrapper>
  );
};

export const ServiceRequestContainer = () => {
  return (
    <ServiceRequestContextProvider>
      <ServiceRequest />
    </ServiceRequestContextProvider>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  background-color: ${Colors.White0};
  padding: 16px;

  overflow-y: auto;
`;
