/* eslint-disable @typescript-eslint/no-explicit-any */
import { Table as RobinTable } from '@robinpowered/ui-kit';
import { Pagination } from './components/Pagination/Pagination';
import styled from '@emotion/styled';
import {
  TicketsListTableContextProvider,
  useTicketsListTableContext,
} from './contexts';
import { useTicketsListPageContext } from 'pages/TicketsListPage/contexts/TicketsListPageContext';
import { ListMeetingServiceRequestsSortByCriteria, SortOrder } from 'generated';
import { Header } from './Header';
import { useRef } from 'react';
import { useFixAntTableHeight } from 'hooks/useFixAntTableHeight';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

const TicketsListTableContainer = () => {
  const { setSortByForListServiceRequestsQuery } = useTicketsListPageContext();
  const { tableData, columns, loadingTicketsListTable, setSkipItems } =
    useTicketsListTableContext();
  const [searchParams] = useSearchParams();
  const serviceRequestKey = searchParams.get('serviceRequestKey');

  const contentRef = useRef<HTMLDivElement>(null);
  const tableHeight = useFixAntTableHeight(contentRef, {
    headerHeight: 80,
    footerHeight: 75,
  });
  const { t } = useTranslation('TicketsListPage');

  const tableCanRender = !loadingTicketsListTable && tableData.length > 0;
  // Fixes a bug with ant table: https://github.com/ant-design/ant-design/issues/26621
  const scroll = tableCanRender
    ? {
        x: 'max-content',
        y: tableHeight,
      }
    : { x: 1200 };

  return (
    <TableWrapper ref={contentRef}>
      <Header title={t('header')} />
      <RobinTable
        sticky={tableCanRender && { offsetHeader: 0 }}
        scroll={scroll}
        dataSource={loadingTicketsListTable ? [] : tableData}
        columns={columns}
        style={{ alignSelf: 'flex-start', flex: 1, width: '100%' }}
        pagination={false}
        loading={loadingTicketsListTable}
        // @TODO: If we ever use bulk selection on this page we will have to consolidate
        // the row selection here with the built in selection from ant tables
        rowSelection={{
          hideSelectAll: true,
          type: 'radio',
          selectedRowKeys: serviceRequestKey ? [serviceRequestKey] : undefined,
        }}
        onChange={(pagination, filters, sorter) => {
          setSortByForListServiceRequestsQuery((prev) => {
            if (
              !sorter ||
              !(sorter as any).order ||
              !(sorter as any).columnKey
            ) {
              // State was not updating when returning undefined, so using null instead
              // even though the end query expects 'undefined' as its param
              return null;
            }

            const order =
              (sorter as any).order === 'ascend'
                ? SortOrder.Ascending
                : SortOrder.Descending;

            const criteria =
              ((sorter as any).columnKey as string) === 'createdAt'
                ? ListMeetingServiceRequestsSortByCriteria.CreatedAt
                : (sorter as any).columnKey === 'updatedAt'
                ? ListMeetingServiceRequestsSortByCriteria.UpdatedAt
                : ListMeetingServiceRequestsSortByCriteria.UpdatedAt;

            return {
              order,
              criteria,
            };
          });

          // Always reset the page to 1 after changing filters
          setSkipItems(0);
        }}
      />
      <PaginationWrapper>
        <Pagination />
      </PaginationWrapper>
    </TableWrapper>
  );
};

export const TicketsListTable = () => {
  return (
    <TicketsListTableContextProvider>
      <TicketsListTableContainer />
    </TicketsListTableContextProvider>
  );
};

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const TableWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;

  // Fix to for antdesign td's where we cant access their styles easily
  td {
    vertical-align: middle;
  }

  // @TODO: Remove this style if we decide to utilitze ant built in selection
  .ant-table-selection-column .ant-radio-wrapper {
    display: none;
  }
`;
