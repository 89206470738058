import {
  createContext,
  useContext,
  FC,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react';
import { useGetServiceRequestDetails } from '../hooks/useGetServiceRequestDetails';
import {
  GetMeetingServiceByIdForServiceRequestQuery,
  TicketQuestionExpectingMenuChoices,
  TicketQuestionMenuOption,
} from 'generated';
import { SelectOption } from 'components/ServiceForm/components/Select';
import {
  MeetingService,
  MeetingServiceEvent,
  useSelectedCategory,
} from '../hooks/useSelectedCategory';
import { useConnectToParent } from '../hooks/useConnectToParent';
import { useValidateData } from '../hooks/useValidateData';
import { Answers } from '../types/ServiceRequestTypes';

export type selectedServiceWithQuestions =
  | GetMeetingServiceByIdForServiceRequestQuery['getMeetingServiceById']['meetingService']
  | undefined;

export type GetMeetingServiceByIdForServiceRequestQuestions =
  NonNullable<selectedServiceWithQuestions>['questions'];

type ServiceRequestContextValue = {
  requestedBy:
    | {
        name?: string | null | undefined;
        avatar?: string | null | undefined;
      }
    | undefined;
  assignedTo: string[];
  loading: boolean;
  selectedServiceWithoutQuestions: MeetingService | undefined;
  selectedCategory: { value: string; label: string; id: string };
  selectCategory: (category: {
    value: string;
    label: string;
    id: string;
  }) => void;
  selectService: (serviceId: string) => void;
  loadingLocation: boolean;
  categories: SelectOption[];
  services: MeetingService[];
  selectedServiceWithQuestions: selectedServiceWithQuestions;
  serviceRequestAnswers: Answers;
  setServiceRequestAnswers: Dispatch<SetStateAction<Answers>>;
  answersHaveError: boolean;
  setAnswersHaveError: Dispatch<SetStateAction<boolean>>;
  isRecurring: boolean | null | undefined;
  gettingServiceWithQuestions: boolean;
} & MeetingServiceEvent;

const ServiceRequestContext = createContext<ServiceRequestContextValue>({
  requestedBy: undefined,
  assignedTo: [],
  locationName: '',
  dueDate: '',
  numOfAttendees: 0,
  loading: true,
  selectedServiceWithoutQuestions: undefined,
  selectedCategory: {
    value: '',
    label: '',
    id: '',
  },
  selectCategory: () => {},
  selectService: () => {},
  loadingLocation: false,
  categories: [],
  services: [],
  selectedServiceWithQuestions: undefined,
  serviceRequestAnswers: new Map(),
  setServiceRequestAnswers: () => null,
  answersHaveError: false,
  setAnswersHaveError: () => null,
  isRecurring: false,
  gettingServiceWithQuestions: false,
});

export const ServiceRequestContextProvider: FC = ({ children }) => {
  const [meetingService, setMeetingService] =
    useState<selectedServiceWithQuestions>(undefined);

  const [serviceRequestAnswers, setServiceRequestAnswers] = useState<Answers>(
    new Map()
  );

  const { isDataValid, answersHaveError, setAnswersHaveError } =
    useValidateData(meetingService);

  const totalPrice = useMemo(() => {
    let total = 0;
    serviceRequestAnswers.forEach((answer, key) => {
      const associatedQuestion = meetingService?.questions.find(
        (q) =>
          q.id === key && q.__typename === 'TicketQuestionExpectingMenuChoices'
      ) as TicketQuestionExpectingMenuChoices | undefined;
      if (associatedQuestion && answer.type === 'choices') {
        answer.options.forEach((option, optionId) => {
          const associatedOption = associatedQuestion.options.find(
            (o) => o.id === optionId
          ) as TicketQuestionMenuOption;

          if (associatedOption && associatedOption.unitPrice) {
            total +=
              (option.quantity ?? 1) *
              Number(associatedOption.unitPrice.amountMajor);
          }
        });
      }
    });

    if (
      !total ||
      !meetingService?.currencyCode ||
      serviceRequestAnswers.size === 0
    ) {
      return undefined;
    }

    return new Intl.NumberFormat(navigator.language, {
      style: 'currency',
      currencyDisplay: 'narrowSymbol',
      currency: meetingService.currencyCode,
    }).format(total);
  }, [
    meetingService?.currencyCode,
    meetingService?.questions,
    serviceRequestAnswers,
  ]);

  const { initialValues } = useConnectToParent(
    {
      meetingServiceId: meetingService?.id,
      meetingVersionId: meetingService?.versionId,
      meetingServiceQuestions: meetingService?.questions,
      answers: serviceRequestAnswers,
      totalPrice,
    },
    () => {
      const validData = isDataValid(serviceRequestAnswers);
      if (!validData) {
        setAnswersHaveError(true);
      }

      return validData;
    }
  );

  const {
    dueDate,
    locationName,
    numOfAttendees,
    requestedBy,
    loading,
    loadingLocation,
  } = useGetServiceRequestDetails(initialValues);
  const {
    selectService,
    selectCategory,
    selectedServiceWithoutQuestions,
    selectedCategory,
    categories,
    services,
    serviceWithQuestions,
    gettingServiceWithQuestions,
  } = useSelectedCategory(initialValues?.spaceId);

  useEffect(() => {
    if (serviceWithQuestions) {
      setMeetingService(
        serviceWithQuestions.getMeetingServiceById.meetingService
      );
    }
  }, [serviceWithQuestions]);

  return (
    <ServiceRequestContext.Provider
      value={{
        requestedBy,
        //@TODO: implement this with the category select dropdown
        assignedTo: [],
        dueDate,
        locationName,
        numOfAttendees,
        loading,
        selectService: (serviceId) => {
          // Clear the question answers and errors when choosing a new service or category
          setServiceRequestAnswers(new Map());
          setAnswersHaveError(false);
          selectService(serviceId);
        },
        selectCategory: (category) => {
          // Clear the question answers and errors when choosing a new service or category
          setServiceRequestAnswers(new Map());
          setAnswersHaveError(false);
          selectCategory(category);
        },
        selectedServiceWithoutQuestions,
        selectedCategory,
        loadingLocation,
        categories,
        services,
        serviceRequestAnswers,
        setServiceRequestAnswers,
        selectedServiceWithQuestions:
          serviceWithQuestions?.getMeetingServiceById.meetingService,
        answersHaveError,
        setAnswersHaveError,
        isRecurring: initialValues?.isRecurring,
        gettingServiceWithQuestions,
      }}
    >
      {children}
    </ServiceRequestContext.Provider>
  );
};

export const useServiceRequestContext = (): ServiceRequestContextValue => {
  return useContext(ServiceRequestContext);
};
