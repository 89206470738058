import { useServiceRequestContext } from 'components/ServiceRequest/contexts/ServiceRequestContext';
import { QuestionSelect } from './QuestionTypes/Components/QuestionSelect';
import { LongAnswer } from './QuestionTypes/Components/LongAnswer';
import { ShortAnswer } from './QuestionTypes/Components/ShortAnswer';
import {
  TicketQuestionExpectingChoices,
  TicketQuestionExpectingMenuChoices,
  TicketQuestionExpectingText,
} from 'generated';
import { QuestionContextProvider } from 'components/ServiceRequest/contexts/QuestionContext';

export const ServiceQuestions = () => {
  const { selectedServiceWithQuestions } = useServiceRequestContext();

  if (!selectedServiceWithQuestions) {
    return null;
  }

  /* Render the appropriate question based on the question type */
  const renderQuestion = (
    question:
      | TicketQuestionExpectingChoices
      | TicketQuestionExpectingMenuChoices
      | TicketQuestionExpectingText
  ) => {
    switch (question.__typename) {
      case 'TicketQuestionExpectingChoices':
      case 'TicketQuestionExpectingMenuChoices': {
        return (
          <QuestionContextProvider
            key={question.id}
            question={question}
            selectType={question.chooseOnlyOneOption ? 'single' : 'multi'}
          >
            <QuestionSelect key={question.id} />
          </QuestionContextProvider>
        );
      }
      case 'TicketQuestionExpectingText': {
        const TextAnswer = question.multilineAnswer ? LongAnswer : ShortAnswer;
        return (
          <QuestionContextProvider key={question.id} question={question}>
            <TextAnswer />
          </QuestionContextProvider>
        );
      }
      default:
        return null;
    }
  };

  return (
    <>
      {selectedServiceWithQuestions.questions.map((question) => {
        return renderQuestion(question);
      })}
    </>
  );
};
